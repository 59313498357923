<script lang="ts">
    import HandContainer from "../lib/HandContainer.svelte";
    import { isTouchDevice } from "../util/is-touch-device";
    $: isAppLink = window.location.host.startsWith("app.");
    $: isAppLinkAndTouch = isAppLink && isTouchDevice();
    const isAndroid = /(android)/i.test(navigator.userAgent);
</script>

<HandContainer backgroundColor="#ffacff" foregroundColor="#500f00">
    <ul>
        {#if isAndroid}
            <li class="step-1">
                <div>
                    <span class:strike-through={isAppLinkAndTouch}>
                        Öffne diese Seite auf deinem Android Telefon im Chrome
                        Browser
                        {#if !isAppLink}
                            Hier ist der
                            <a
                                href="https://app.atopic.app/#/download"
                                rel="nofollow">Link</a
                            >
                        {/if}
                    </span>
                    {#if !isAppLinkAndTouch}
                        <img
                            src="/download-qr.svg"
                            class="qr"
                            alt="qr code to download the app"
                        />
                    {/if}
                </div>
            </li>
            <li>
                Klicke auf die drei vertikalen Punkte neben der Adressleiste
            </li>
            <li>Folge den Anweisungen auf dem Bildschirm</li>
        {:else}
            <li class="step-1">
                <div>
                    <span class:strike-through={isAppLinkAndTouch}>
                        Öffne diese Seite in deinem mobilen Browser.
                        {#if !isAppLink}
                            Hier ist der
                            <a
                                href="https://app.atopic.app/#/download"
                                rel="nofollow">Link</a
                            >
                        {/if}
                    </span>
                    {#if !isAppLinkAndTouch}
                        <img
                            src="/download-qr.svg"
                            class="qr"
                            alt="qr code to download the app"
                        />
                    {/if}
                </div>
            </li>
            <li>Klicke auf das Teilen Menü deines Browsers</li>
            <li>Scrolle nach unten und tippe auf Zum Home-Bildschirm</li>
            <li>Bestätige durch Tippen auf Hinzufügen</li>
        {/if}
    </ul>
</HandContainer>

<style>
    h1 {
        position: absolute;
        font-size: 3rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0.5rem;
        width: 100%;
        top: 1rem;
    }

    a {
        text-decoration: underline;
    }

    .strike-through {
        text-decoration: line-through;
    }

    ul {
        list-style: none;
        counter-reset: position;
        padding: env(safe-area-inset-top) 0.5rem 0.5rem 0.5rem;
    }

    .step-1 > div {
        background: var(--bg-color);
        display: inline-flex;
        align-items: center;
    }

    .qr {
        mix-blend-mode: multiply;
        display: block;
    }

    li {
        padding: 0.5rem 0;
        padding-left: 2.5rem;
    }

    li::before {
        font-family: "inter var";
        -moz-font-feature-settings: "ccmp" 1;
        -webkit-font-feature-settings: "ccmp" 1;
        font-feature-settings: "ccmp" 1;
        counter-increment: position;
        content: "1\0020DD";
        display: inline-block;
        width: 2.5rem;
        margin-left: -2.5rem;
    }

    li:nth-child(2):before {
        content: "2\0020DD";
    }
    li:nth-child(3):before {
        content: "3\0020DD";
    }
    li:nth-child(4):before {
        content: "4\0020DD";
    }
</style>
