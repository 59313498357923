<script lang="ts">
    import clipboard from "clipboardy";
    import { push } from "svelte-spa-router";
    import { flip } from "svelte/animate";
    import AnimatedPromise from "../lib/AnimatedPromise.svelte";
    import AtopicMeta from "../lib/AtopicMeta.svelte";
    import BottomNavigation from "../lib/BottomNavigation.svelte";
    import Button from "../lib/Button.svelte";
    import ButtonGroup from "../lib/ButtonGroup.svelte";
    import Dialog from "../lib/Dialog.svelte";
    import ShareButton from "../lib/ShareButton.svelte";
    import Title from "../lib/faces/Title.svelte";
    import { myAtopics, sharedLists } from "../stores/atopic-store";
    import { ditch } from "../transitions/ditch";
    import type { Atopic } from "../types/Atopic";
    import { encode } from "../util/share";
    import { fade } from "svelte/transition";

    let shareAllDialogVisible = false;
    let listName = "";

    function handleClick(atopic: Atopic) {
        push(`#/atopic/${atopic.id}/instruction`);
    }

    function customFlip(
        node: Element,
        fromTo: {
            from: DOMRect;
            to: DOMRect;
        }
    ) {
        // @ts-expect-error
        if (node.style.animation) node.style = null;
        return flip(node, fromTo, { duration: 200, delay: 100 });
    }

    let shareAllPromise: Promise<void> | undefined;

    const wait = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

    async function handleShareAll(name: string, atopics: Atopic[]) {
        const sharePart = encode({ atopics, listName: name.length > 0 ? name : undefined });
        const url = `${window.location.origin}/#/share/${sharePart}`;
        await clipboard.write(url);
        await wait(2500);
        shareAllDialogVisible = false;
    }

    $: if (shareAllDialogVisible) {
        shareAllPromise = undefined;
        listName = "";
    }

    $: hasSharedLists = $sharedLists.size > 0;
</script>

<div class="container" class:offset-menu={hasSharedLists}>
    {#if $myAtopics.length === 0}
        <div class="not-found">Keine Atopics gefunden.</div>
    {/if}
    {#each $myAtopics as atopic (atopic.id)}
        <div
            class="atopic"
            animate:customFlip
            in:ditch|global={{ inOut: true, resetStyle: true }}
            out:ditch|global={{ inOut: false, resetStyle: true }}
        >
            <div class="meta">
                <ShareButton {atopic}>
                    Teilen
                    <svelte:fragment slot="shared">Link kopiert</svelte:fragment>
                </ShareButton>
                <AtopicMeta {atopic} />
            </div>
            <Title {atopic} fullWidth on:click={() => handleClick(atopic)} />
        </div>
    {/each}
</div>
<Dialog open={shareAllDialogVisible} top>
    <div class="dialog-input-container">
        <input
            type="text"
            bind:value={listName}
            placeholder="Meine Atopics"
            pattern="[ \-a-zA-Z0-9]+"
            minlength="4"
            disabled={!!shareAllPromise}
        />
        <!-- <span class="prefix">Atopics</span> -->
    </div>
    <svelte:fragment slot="controls">
        <AnimatedPromise promise={shareAllPromise}>
            <ButtonGroup center>
                <Button secondary on:click={() => (shareAllDialogVisible = false)}>
                    Abbrechen
                </Button>
                <Button
                    disabled={!listName}
                    on:click={() => (shareAllPromise = handleShareAll(listName, $myAtopics))}
                >
                    Liste teilen
                </Button>
            </ButtonGroup>
            <svelte:fragment slot="alt">
                <ButtonGroup center>
                    <Button secondary caps={false}>Link in Zwischenablage kopiert</Button>
                </ButtonGroup>
            </svelte:fragment>
        </AnimatedPromise>
    </svelte:fragment>
</Dialog>

<BottomNavigation atopicMeta>
    <Button on:click={() => (shareAllDialogVisible = true)} disabled={$myAtopics.length < 2}>
        Alle Teilen
    </Button>
</BottomNavigation>

<style>
    .atopic {
        margin-bottom: 0.5rem;
    }
    .meta {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding: 0.5rem 0;
    }
    .container {
        padding: env(safe-area-inset-top) 0.5rem 2.5rem 0.5rem;
        overflow: scroll;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
    }

    .container.offset-menu {
        padding-top: calc(env(safe-area-inset-top) + 3rem);
    }

    .not-found {
        padding-top: 0.5rem;
        height: 0;
        color: var(--color-inactive);
    }

    .dialog-input-container {
        display: flex;
        background: white;
        align-items: center;
        padding: 0 0.5rem;
        justify-content: space-around;
    }

    .dialog-input-container input {
        border: none;
        text-align: center;
        font-size: 1.1rem;
        padding: 0.5rem;
        width: 100%;
    }

    .dialog-input-container input:focus {
        border: none;
        outline: 0;
        outline-color: transparent;
        outline-style: none;
    }

    .button-group-wrapper {
        display: grid;
        grid-template: "container";
        place-items: center;
        place-content: center;
    }
    .button-group-wrapper > div {
        grid-area: container;
    }
</style>
