<script lang="ts">
    import { location } from "svelte-spa-router";
    import Button from "./Button.svelte";

    import ButtonGroup from "./ButtonGroup.svelte";
    import { createMediaStore } from "svelte-media-queries";
    import { onDestroy } from "svelte";
    $: activeRoute = (route: string) => $location.startsWith(route);

    const matches = createMediaStore({
        desktop: "screen and (min-width: 800px)",
    });
    onDestroy(() => matches.destroy());
</script>

<nav>
    <ButtonGroup center={!!$matches?.desktop} scroll>
        <a href="#/about">
            <Button
                active={!activeRoute("/info") &&
                    !activeRoute("/impressum") &&
                    !activeRoute("/datenschutz") &&
                    !activeRoute("/download") &&
                    !activeRoute("/share")}>About</Button
            >
        </a>
        <a href="#/info">
            <Button active={activeRoute("/info")}>Info</Button>
        </a>
        <a href="#/download">
            <Button active={activeRoute("/download")}>Download</Button>
        </a>
        <a href="#/impressum">
            <Button active={activeRoute("/impressum")}>Impressum</Button>
        </a>
        <a href="#/datenschutz">
            <Button active={activeRoute("/datenschutz")}>Datenschutz</Button>
        </a>
    </ButtonGroup>
</nav>

<style>
    nav {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0.5rem;
        z-index: 2;
    }

    @media screen and (min-width: 800px) {
        nav {
            bottom: auto;
            top: 0;
        }
    }
</style>
