<script lang="ts">
    import Button from "./Button.svelte";
    import { fade, fly } from "svelte/transition";
    import {
        atopics,
        atopicsFiltered,
        currentDurationFilter,
        currentLocale,
        currentLocationFilter,
        DEFAULT_LOCATION_FILTER,
        doneFilter,
        durations,
        likedFilter,
        locations,
    } from "../stores/atopic-store";
    import clickOutside from "../util/click-outside";
    import ButtonGroup from "./ButtonGroup.svelte";
    import { location, push } from "svelte-spa-router";
    import Heart from "./Heart.icon.svelte";

    export let noFilter = false;
    export let atopicMeta = false;
    export let above = true;
    export let hideCenterSlot = false;

    type SubMenuType = "filter" | "menu" | undefined;
    let active: SubMenuType;

    const toggle = (which: SubMenuType) => () => (active = which === active ? undefined : which);

    const toggleLocation = (location: string) => () => {
        currentLocationFilter.update((l) => (l === location ? DEFAULT_LOCATION_FILTER : location));
    };
    $: activeDuration = (duration: string) => duration === $currentDurationFilter;
    $: activeLocation = (location: string) => location === $currentLocationFilter;
    $: activeLocale = (locale: "en" | "de") => locale === $currentLocale;
    $: activeRoute = (route: string) => $location.startsWith(route);

    const toggleDuration = (duration: string) => () => {
        currentDurationFilter.update((d) => (d === duration ? undefined : duration));
    };

    const locationChange = (locale: "de" | "en") => () => {
        currentLocale.set(locale);
    };
</script>

<nav class="main" use:clickOutside={toggle(undefined)} class:above out:fade={{ duration: 0 }}>
    <div transition:fly={{ duration: 200, x: -30 }}>
        {#if $$slots.left}
            <slot name="left" />
        {:else}
            <Button
                disabled={noFilter}
                on:click={toggle("filter")}
                active={active === "filter" || (!noFilter && $atopicsFiltered)}
            >
                Filter
            </Button>
        {/if}
    </div>
    {#if !hideCenterSlot}
        <div class="center">
            <div in:fly|global={{ duration: 200, x: -30 }}>
                <slot />
            </div>
        </div>
    {/if}
    <Button on:click={toggle("menu")} active={active === "menu"}>Menu</Button>
    {#if active === "filter"}
        <nav
            class="sub"
            transition:fly={{ y: 30, duration: 200 }}
            on:click={toggle(undefined)}
            on:keydown
        >
            {#if atopicMeta}
                <ButtonGroup>
                    <Button
                        caps={false}
                        active={$likedFilter}
                        on:click={() => likedFilter.update((l) => !l)}
                        glyph
                        secondary
                    >
                        <Heart active={$likedFilter} secondary />
                    </Button>
                    <Button
                        caps={false}
                        active={$doneFilter}
                        on:click={() => doneFilter.update((l) => !l)}
                        secondary
                        >{$atopics.filter((a) => a.done).length}/{$atopics.length}<span
                            class="glyph">✓</span
                        ></Button
                    >
                </ButtonGroup>
            {/if}
            <ButtonGroup>
                {#each $durations as duration}
                    <Button
                        secondary
                        caps={false}
                        active={activeDuration(duration.key)}
                        on:click={toggleDuration(duration.key)}>{duration.value}</Button
                    >
                {/each}
            </ButtonGroup>
            <ButtonGroup>
                {#each $locations as location}
                    {#if location.key !== DEFAULT_LOCATION_FILTER}
                        <Button
                            secondary
                            caps={false}
                            active={activeLocation(location.key)}
                            on:click={toggleLocation(location.key)}>{location.value}</Button
                        >
                    {/if}
                {/each}
            </ButtonGroup>
        </nav>
    {:else if active === "menu"}
        <nav class="sub" transition:fly={{ y: 30, duration: 200 }}>
            <ButtonGroup alignRight>
                <Button
                    on:click={() => !activeRoute("/atopic") && push("/atopic")}
                    caps={false}
                    active={activeRoute("/atopic")}
                >
                    Atopics
                </Button>

                <a href="#/my-atopics">
                    <Button
                        caps={false}
                        active={activeRoute("/my-atopics") || activeRoute("/list")}
                    >
                        myAtopics
                    </Button>
                </a>
            </ButtonGroup>
            <ButtonGroup alignRight>
                <a href="#/extra">
                    <Button caps={false} active={activeRoute("/extra")} secondary>Extra</Button>
                </a>
                <a href="#/about">
                    <Button caps={false} active={activeRoute("/about")} secondary>About</Button>
                </a>
            </ButtonGroup>
            <ButtonGroup alignRight>
                <a href="#/imprint">
                    <Button caps={false} active={activeRoute("/imprint")} secondary>
                        Impressum
                    </Button>
                </a>
                <a href="#/data-policy">
                    <Button caps={false} active={activeRoute("/data-policy")} secondary>
                        Datenschutz
                    </Button>
                </a>
                <!-- <div class="flex">
                    <Button active={activeLocale("de")} on:click={locationChange("de")} secondary
                        >DE</Button
                    >
                    <Button active={activeLocale("en")} on:click={locationChange("en")} secondary
                        >EN</Button
                    >
                </div> -->
            </ButtonGroup>
        </nav>
    {/if}
</nav>

<style>
    .center {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    nav {
        position: fixed;
        bottom: env(safe-area-inset-bottom, 0);
        left: 0;
        width: 100%;
        padding: 0.5rem;
    }

    nav.above {
        z-index: 2;
    }

    nav.main {
        display: flex;
        justify-content: space-between;
    }

    nav.sub {
        bottom: calc(3rem + env(safe-area-inset-bottom, 0));
    }

    .flex {
        display: flex;
    }
</style>
