<script lang="ts">
    import Ditchable from "../lib/Ditchable.svelte";
    import { faces } from "../routes/routes";
    import { currentLocale, like } from "../stores/atopic-store";
    import { reactions as reactionsFn, updateReactionData } from "../stores/reaction-store";
    import { atopicProgressState, finish, initialize, start } from "../stores/progress-store";
    import { push } from "svelte-spa-router";
    import { onMount } from "svelte";
    import DataLoader from "./DataLoader.svelte";
    import ReactionPreloader from "./ReactionPreloader.svelte";
    import UploadImageModal from "./UploadImageModal.svelte";
    import type { ReactionType } from "../types/Reaction";
    import UploadTextDialog from "./UploadTextDialog.svelte";
    import AtopicNavigation from "./AtopicNavigation.svelte";
    import UploadAudioDialog from "./UploadAudioDialog.svelte";
    import AtopicMeta from "./AtopicMeta.svelte";
    import type { Atopic } from "../types/Atopic";

    export let face: string;
    export let atopic: Atopic;
    export let handleDitch: () => void;

    onMount(() => {
        // reset if atopic has been switched
        initialize(atopic);
    });

    let ditchable = false;
    let draggable = false;
    let morphable = false;
    $: url = `/api/${$currentLocale}/reactions/${atopic.id}`;

    function isDitchable() {
        return ditchable ?? true;
    }
    function isDraggable() {
        return draggable ?? true;
    }

    async function handleAtopicStart() {
        await push(`/atopic/${atopic.id}/instruction`);
        start(atopic);
    }

    async function handleAtopicDone() {
        await push(`/atopic/${atopic.id}/reactions`);
        finish(atopic);
    }

    let reactionIndex = 0;
    // let reloadReactions;
    let reactionDialogOpen: ReactionType | undefined;
    let file: File | undefined;
    $: reactions = reactionsFn(atopic);
    $: reaction = $reactions?.[reactionIndex];
    $: face, (reactionIndex = 0);

    function handleNextReaction() {
        reactionIndex = (reactionIndex + 1) % $reactions.length;
    }

    function handlePreviousReaction() {
        reactionIndex = reactionIndex - 1 < 0 ? $reactions.length - 1 : reactionIndex - 1;
    }

    export function handleReactionUpload(
        e: Event & {
            detail: {
                type: ReactionType;
                file?: File;
            };
        }
    ) {
        reactionDialogOpen = e.detail.type;
        file = e.detail?.file;
    }

    function handleDialogClose() {
        reactionDialogOpen = undefined;
        file = undefined;
    }

    function handleClick() {
        console.log("handle click");
        if (face === "title") push(`#/atopic/${atopic.id}/instruction`);
        else if (face === "instruction") push(`#/atopic/${atopic.id}`);
    }

    async function handleReactionUploadError() {}
</script>

<DataLoader
    {url}
    on:data={(e) => updateReactionData(e.detail.data)}
    on:error={(e) => updateReactionData([])}
/>
<ReactionPreloader reactions={$reactions} />

<Ditchable
    {handleDitch}
    {isDitchable}
    {isDraggable}
    morphable={morphable ?? false}
    currentFace={face}
>
    <svelte:component
        this={faces[face] || null}
        on:click={handleClick}
        bind:ditchable
        bind:draggable
        bind:morphable
        {atopic}
        {reaction}
    />
    <div slot="nav-top">
        {#if !(face === "reactions" && $reactions.length === 0)}
            <AtopicMeta {atopic} />
        {/if}
    </div>
    <div
        slot="nav-bottom"
        class="nav-bottom"
        let:width
        class:fixed-width={face === "reactions"}
        style="--width: {width || 0}px"
    >
        <AtopicNavigation
            {atopic}
            atopicProgress={$atopicProgressState}
            {face}
            {reactionIndex}
            reactions={$reactions}
            on:atopicDone={handleAtopicDone}
            on:atopicStart={handleAtopicStart}
            on:nextReaction={handleNextReaction}
            on:previousReaction={handlePreviousReaction}
            on:reactionUpload={handleReactionUpload}
        />
    </div>
</Ditchable>
{#if reactionDialogOpen === "image-reaction"}
    <UploadImageModal
        {atopic}
        {file}
        on:success={handleAtopicDone}
        on:error={handleReactionUploadError}
        on:close={handleDialogClose}
    />
{:else if reactionDialogOpen === "text-reaction"}
    <UploadTextDialog
        {atopic}
        on:success={handleAtopicDone}
        on:error={handleReactionUploadError}
        on:close={handleDialogClose}
    />
{:else if reactionDialogOpen === "audio-reaction"}
    <UploadAudioDialog
        {atopic}
        on:success={handleAtopicDone}
        on:error={handleReactionUploadError}
        on:close={handleDialogClose}
    />
{/if}

<style>
    .nav-bottom {
        display: flex;
        gap: 0.5rem;
        align-items: baseline;
    }

    .nav-bottom.fixed-width {
        width: var(--width, 100%);
        transition: width 200ms 0ms;
    }
</style>
