<script lang="ts">
    import type { Atopic } from "../../types/Atopic";
    import AtopicCredit from "../AtopicCredit.svelte";
    import AtopicProgressOverlay from "../AtopicProgressOverlay.svelte";
    import CardContainer from "../CardContainer.svelte";

    export let atopic: Atopic;
    export const ditchable = true;
    export const draggable = true;
</script>

<AtopicProgressOverlay on:click on:keydown>
    <CardContainer>
        <h2>{@html atopic.instruction}</h2>
        {#if atopic.creditInstruction}
            <AtopicCredit credit={atopic.creditInstruction} />
        {/if}
    </CardContainer>
</AtopicProgressOverlay>

<style>
    h2 {
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 600;
        word-wrap: break-word;
        hyphens: auto;
        z-index: 1;
    }
</style>
