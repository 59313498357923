<script lang="ts">
    import { ditch } from "../transitions/ditch";
    import type { Atopic } from "../types/Atopic";
    import Title from "./faces/Title.svelte";

    export let atopic: Atopic;
</script>

<div class="stack" on:click on:keypress out:ditch={{ inOut: false }}>
    <div style="--r: 5deg; --b: 70%" in:ditch|global={{ inOut: true }}>
        <Title {atopic} fullWidth noImage />
    </div>
    <div style="--r: -2deg; --b: 80%" in:ditch|global={{ inOut: true }}>
        <Title {atopic} fullWidth noImage />
    </div>
    <div style="--r: 2deg; --b: 90%" in:ditch|global={{ inOut: true }}>
        <Title {atopic} fullWidth noImage />
    </div>
    <div in:ditch|global={{ inOut: true }}><Title {atopic} fullWidth /></div>
</div>

<style>
    .stack {
        display: grid;
        grid-template: "container";
        place-items: center;
        place-content: center;
    }

    .stack > div {
        grid-area: container;
        z-index: 1;
        position: relative;
        transform: rotate(var(--r));
        filter: brightness(var(--b, 100%));
    }
</style>
