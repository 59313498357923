<script lang="ts">
    import { push, params as paramsStore } from "svelte-spa-router";
    import Atopic from "../lib/Atopic.svelte";
    import BottomNavigation from "../lib/BottomNavigation.svelte";
    import { Howl } from "howler";
    import {
        atopics,
        getAtopicByID,
        getRandomAtopic,
        isAtopicMatchingFilter,
        currentDurationFilter,
        currentLocationFilter,
    } from "../stores/atopic-store";
    import TopNavigation from "../lib/AtopicTopNavigation.svelte";
    import NotFound from "./NotFound.svelte";
    import Button from "../lib/Button.svelte";
    import Shuffle from "../lib/Shuffle.icon.svelte";
    import { ditch } from "../transitions/ditch";
    import Image from "../lib/Image.svelte";
    import { atopicProgressState, backgroundAudioMuted } from "../stores/progress-store";
    import { derived } from "svelte/store";
    import { onDestroy } from "svelte";

    export let params: { atopicid: string; face: string };
    // use the params store to get a value which only changes when the atopic id changes
    const currentAtopicId = derived(paramsStore, ($a) => $a?.atopicid);

    $: console.log("atopic id changed", $currentAtopicId);

    let handleReactionUpload: (e: Event) => void;
    let noNewAtopicFound = false;
    $: progressOverlayVisible = $atopicProgressState === "started";
    $: currentAtopic = getAtopicByID($currentAtopicId!, $atopics);
    $: notFound = !!$currentAtopicId && !currentAtopic;
    function onFilterChange() {
        if (!currentAtopic) return;
        let currentAtopicNotMatchingFilter = !isAtopicMatchingFilter(
            currentAtopic,
            $currentDurationFilter,
            $currentLocationFilter
        );
        if (currentAtopicNotMatchingFilter || noNewAtopicFound) {
            handleDitch(noNewAtopicFound);
        }
    }
    $: onFilterChange(), $currentDurationFilter, $currentLocationFilter, currentAtopic;

    async function handleDitch(includeCurrentAtopic: boolean = false) {
        const randomAtopic = getRandomAtopic(includeCurrentAtopic ? undefined : currentAtopic);
        if (randomAtopic) {
            noNewAtopicFound = false;
            await push("/atopic/" + randomAtopic.id);
        } else {
            noNewAtopicFound = true;
        }
    }

    $: currentFace = params.face || "title";

    // audio
    let audioClip: Howl | null = null;
    let audioId: number | undefined = undefined;
    $: shouldPlayAudio = !$backgroundAudioMuted && $atopicProgressState === "started";
    function loadUnloadAudio() {
        if (audioClip) {
            audioClip.unload();
            audioClip = null;
        }
        if (currentAtopic?.backgroundAudio) {
            audioId = undefined;
            audioClip = new Howl({ src: [currentAtopic.backgroundAudio.url], html5: true });
            audioClip.on("fade", (_audioId) => {
                if (!audioClip) return;
                if (audioClip.volume(_audioId) !== 0) return;
                if ($atopicProgressState !== "started") {
                    audioClip.stop(_audioId);
                    console.log("stop audio");
                } else {
                    audioClip.pause(_audioId);
                    console.log("pause audio");
                }
            });
        }
    }
    $: loadUnloadAudio(), $currentAtopicId;

    function startStopAudio() {
        const fadeTime = 500;
        if (!audioClip) return;
        if (shouldPlayAudio) {
            try {
                audioClip.fade(0, 1, fadeTime, audioId);
                audioId = audioClip.play(audioId);
                console.log("play audio");
            } catch (e) {
                console.log("playing of audio file failed", e);
            }
        } else {
            if (audioClip.playing()) {
                audioClip.fade(1, 0, fadeTime, audioId);
                // will be paused by fade event
            }
            // reset sound when focus mode existed
        }
    }

    $: startStopAudio(), shouldPlayAudio;
</script>

<!-- preload images -->
{#if $atopics}
    {#each $atopics as atopic}
        {#if atopic?.coverImage}
            <Image image={atopic.coverImage} preload />
        {/if}
    {/each}
{/if}

<!-- <div transition:fade|global={{ duration: 50 }} id="single-atopic-page"> -->
{#if !currentAtopic}
    {#if notFound}
        <NotFound />
    {/if}
{:else}
    {#if noNewAtopicFound}
        <div class="not-found-with-filter">Kein Atopic für deine Filter gefunden!</div>
    {:else}
        <TopNavigation
            atopic={currentAtopic}
            face={currentFace}
            above={!progressOverlayVisible}
            on:reactionUpload={handleReactionUpload}
        />
        {#key $currentAtopicId}
            <div
                class="atopic-container"
                in:ditch|global={{ inOut: true }}
                out:ditch|local={{ inOut: false }}
                id={currentAtopic.id}
                class:above={progressOverlayVisible}
            >
                <Atopic
                    atopic={currentAtopic}
                    face={currentFace}
                    {handleDitch}
                    bind:handleReactionUpload
                />
            </div>
        {/key}
    {/if}
    <BottomNavigation above={!progressOverlayVisible}>
        <Button on:click={() => handleDitch()} glyph><Shuffle /></Button>
    </BottomNavigation>
{/if}

<!-- </div> -->

<style>
    #single-atopic-page {
        width: 100%;
        height: 100%;
    }
    div.atopic-container {
        position: absolute;
        top: 50vh;
        left: 50vw;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
    }

    div.above {
        z-index: 5;
    }

    .not-found-with-filter {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) rotateZ(1deg);
        /* text-align: center; */
        color: var(--color-inactive);
        z-index: 0;
    }
</style>
